<template>
  <main>
    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light banner" style="background-image:url(images/welcomeBanner.png)">
      <div class="col-md-5 p-lg-5 mx-auto my-5" >
        <h1 class="display-4 fw-normal">IMMO TEMPLATES</h1>
        <p class="lead fw-normal">Descubre las increíbles plantillas que IMMO tiene para ti.</p>
      </div>
    </div>

    <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
      <SiteCard site="0"/>
      <SiteCard site="1"/>
    </div>
    <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
      <SiteCard site="2"/>
      <SiteCard site="3"/>
    </div>
  </main>
</template>

<script>
import SiteCard from '@/components/SiteCard.vue'
export default {
  components:{
    SiteCard
  }
}
</script>
<style scoped>
.banner{
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position-x: center; */
    background-position-y: bottom;
}
</style>