import { createStore } from 'vuex'

export default createStore({
    state: {
        sites: [{
                "key": 0,
                "image": 'homeland.png',
                "name": 'Homeland',
                "look": 'dark',
                "url": 'https://homeland.immosystem.com.mx',
                "imgBackground": "light"
            },
            {
                "key": 1,
                "image": 'royalestate.png',
                "name": 'Royal Estate',
                "look": 'light',
                "url": 'https://royalestate.immosystem.com.mx',
                "imgBackground": "dark"
            },
            {
                "key": 2,
                "image": 'loreen.png',
                "name": 'Loreen',
                "look": 'light',
                "url": 'https://loreen.immosystem.com.mx',
                "imgBackground": "body"
            },
            {
                "key": 3,
                "image": 'cozy.png',
                "name": 'Cozy',
                "look": 'dark',
                "url": 'https://cozy.immosystem.com.mx',
                "imgBackground": "light"
            },

        ]
    },
    mutations: {},
    actions: {},
    getters: {
        getSites: (state) => (id) => {
            return state.sites.find(site => site.key == id)
        }
    }
})