<template>
    <header class="site-header sticky-top py-1">
        <nav class="container d-flex flex-column flex-md-row justify-content-between">
            <a class="py-2" href="https://immosystem.com.mx/" aria-label="Product">
                <img class="logo" src="images/immoCRMminimal.png" alt="IMMO CRM 360">
            </a>
            
            <a class="py-2 d-none d-md-inline-block" href="#"> <i class="far fa-envelope"></i> : hola@immosystem.com.mx</a>
            <a class="py-2 d-none d-md-inline-block" href="#"> <i class="fas fa-phone-alt"></i> : (998) 310 2315‬</a>
            <a class="py-2 d-none d-md-inline-block" data-toggle="tooltip" data-placement="top" title="Contactar por WhatsApp" href="https://api.whatsapp.com/send?text=Buen día, estoy interesado(a) en recibir información sobre una de las plantillas disponibles para mi sitio web. (docs.immosystem.com.mx/)&amp;phone=529983401956" target="_blank" ><i class="fab fa-whatsapp"></i> : (998) 340 1956</a>
        </nav>
    </header>
</template>
<style scoped>
.logo{
    width: 25px;
}

</style>